import classNames from "classnames"
import PropTypes from "prop-types"
import { useMemo } from "react"

import { TeamPicture } from "../../../../../components/league/TeamPicture/TeamPicture.jsx"
import { mapToObject, sortTableData } from "../../../../../utilities/helpers.js"
import { removeLeadingSpaces } from "../../../../league/service/service.js"
import { TeamHeading } from "../../parts/TeamHeading/TeamHeading.jsx"
import { Title } from "../../parts/Title/Title.jsx"
import { VisualPlayerPicture } from "../../parts/VisualPlayerPicture/VisualPlayerPicture.jsx"
import { BaseLayout } from "../Base.jsx"

import "./BestScorers.scss"

export const BestScorers = (props) => {
    const { data, pagination, ctx } = props

    const sortedScorers = useMemo(() => {
        const players = data?.leagueTeam?.players?.map((player, index) => ({
            id: index,
            values: new Map(Object.entries({
                ...player,
                name: player.firstname + " " + player.lastname,
            })),
        })) ?? []

        return players.length > 0 ? sortTableData(players, { key: "nbGoals", order: "DESC" }, { key: "firstname", order: "ASC" }) : []
    }, [ data ])

    const { currentPage, limit } = pagination ?? {}

    const paginatedScorers = useMemo(() => {
        const startIndex = (currentPage - 1) * limit
        const endIndex = startIndex + limit
        return sortedScorers.slice(startIndex, endIndex)
    }, [ sortedScorers, currentPage, limit ])

    return (
        <BaseLayout decorator="left" withGradient>
            <TeamHeading data={data} ctx={ctx} />
            <Title size={[ 30 ]} title={"CLASSEMENT DES BUTEURS DE L’ÉQUIPE"} cls={"uppercase"} margin={[ 6, 0, 30, 0 ]} />
            <VisualPlayerPicture
                picture={
                    ctx?.selectedTeamPlayerPhoto
                    || paginatedScorers[0]?.values.get("businessLeaguePictureUrl")
                    || null
                }
                width={570}
                zIndex={1}
                coordinates={{
                    bottom: 0,
                    left: 0,
                }}
            />
            <div className={"table big bestScorers"}>
                <table>
                    <thead>
                        <tr>
                            <th key={"tableHeadKey-" + "0"} className="numbers">{columnsDisplayNames?.get("id")}</th>
                            {columns?.map((column, i) => (
                                <th
                                    key={"tableHeadKey-" + i}
                                    className={classNames({ first: i === 0 })}
                                    style={{ textAlign: column.align || "left" }}
                                >
                                    {columnsDisplayNames?.get(column.name) === "" ? "" : (columnsDisplayNames?.get(column.name)) || column.name}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedScorers.map((row, i) => {
                            const player = mapToObject(row?.values)
                            return (
                                <tr
                                    key={"tableBodyKey-" + i}
                                    className={classNames({ highlighted: player.id === ctx.userId })}
                                >
                                    <td className="numbers tw-regular"
                                        key={"tableCell-row" + i + "-" + "0"}>{(currentPage - 1) * limit + i + 1}</td>
                                    {columns?.map((column, j) => (
                                        <td
                                            key={"tableCell-row" + i + "-" + j}
                                            className={classNames("tw-bold", {
                                                first: j === 0,
                                                [column.cls]: column.cls,
                                            })}
                                            style={{ textAlign: column.align }}
                                        >
                                            {column.render ? column.render(row) : row.values.get(column.name)}
                                        </td>
                                    ))}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </BaseLayout>
    )
}

const columnsDisplayNames = new Map([
    [ "id", "Rang" ],
    [ "name", "Joueur" ],
    [ "nbGoals", "Buts" ],
    [ "nbGamePlayed", "Apparitions" ],
    [ "goalsPerGame", "Buts / Matchs" ],
])

const columns = [
    {
        align: "left", name: "name", render: (row) => {
            const player = mapToObject(row?.values)
            const firstName = removeLeadingSpaces(player?.firstname)

            return (
                <div className={"playerLine"}>
                    <TeamPicture
                        size={100}
                        image={player?.businessLeaguePictureUrl ?? player?.pictureUrl}
                        placeHolder={"/assets/images/user-placeholder-b.svg"}
                    />

                    <div className={"playerLine__content"}>
                        <div className={"tw-bold"}>
                            {firstName ?
                                <span>{firstName} {player?.lastname.slice(0, 1) + "."}</span> :
                                <span>{player?.lastname}</span>
                            }
                        </div>
                        {player?.emailAddress && <span className={"teamName tw-regular"}>{player?.jobTitle || "N/C"}</span>}
                    </div>
                </div>
            )
        },
    },
    { align: "center", name: "nbGoals" },
    { align: "center", name: "nbGamePlayed" },
    {
        align: "center", name: "goalsPerGame", render: (row) => {
            const player = mapToObject(row?.values)
            const goals = player?.nbGoals
            const games = player?.nbGamePlayed || 1

            return games ? (goals / games).toFixed(1) : 0
        },
    },
]

BestScorers.propTypes = {
    ctx: PropTypes.object,
    data: PropTypes.object,
    pagination: PropTypes.object,
}
