import { motion } from "framer-motion"
import { useEffect, useMemo, useRef, useState } from "react"
import { useLocation, useParams } from "react-router-dom"

import { LeagueHeader } from "../../../components/league/LeagueHeader/LeagueHeader.jsx"
import PlayerCard from "../../../components/league/PlayerCard/PlayerCard.jsx"
import StatsSummary from "../../../components/league/StatsSummary/StatsSummary.jsx"
import { TeamHeading } from "../../../components/league/TeamHeading/TeamHeading.jsx"
import { VideoGrid } from "../../../components/league/VideoGrid/VideoGrid.jsx"
import Preloader from "../../../components/loaders/preloader/preloader.jsx"
import { AnimationPresets } from "../../../utilities/animations.js"
import { getLeagueTeamById, getPlayerProfile, getPlayerVideos } from "../api/myLeague.js"
import { getScoreLabel, prepareVideosFromGroup } from "../service/service.js"

import "./LeaguePlayerDetails.scss"

export const LeaguePlayerDetails = () => {
    const [ playerProfile, setPlayerProfile ] = useState(null)
    const [ isLoading, setIsLoading ] = useState(false)
    const { teamId, playerId  } = useParams()
    const location = useLocation()
    const videoGridRef = useRef(null)

    const [ currentTeam, setCurrentTeam ] = useState(null)

    useEffect(() => {
        setIsLoading(true)
        getLeagueTeamById(teamId).then(team => {
            setCurrentTeam(team)
        }).finally(() => {
            setIsLoading(false)
        })
    }, [ teamId ])

    useEffect(() => {
        setIsLoading(true)
        getPlayerProfile(playerId).then((response) => {
            setPlayerProfile(response)
            setIsLoading(false)
            if (teamId) {
                getPlayerVideos(teamId, playerId).then((videos) => {
                    setPlayerProfile((prev) => ({ ...prev, goalsItems: videos }))
                })
            }
        })
    }, [ playerId, location.hash ])

    const playerStatsSummary = playerProfile ? {
        draws: playerProfile.draws,
        gamesSeries: playerProfile.fivePastGamesResult?.split(","),
        goals: {
            conceded: playerProfile.teamGoals,
            scored: playerProfile.goals,
            team: playerProfile.teamGoals,
        },
        losses: playerProfile.losses,
        played: playerProfile.gamesPlayed,
        wins: playerProfile.wins,
    } : null

    const formattedPlayerVideos = useMemo(() => {
        return prepareVideosFromGroup(playerProfile?.goalsItems, (group) => {
            return (
                <>
                    <h3>VS</h3>
                    <p>{group.gameDetails?.visitorTeam?.team}</p>
                    <h3 className={"score"}>{getScoreLabel(group)}</h3>
                </>
            )
        })
    }, [ playerProfile?.goalsItems, playerId ])

    useEffect(() => {
        if (formattedPlayerVideos?.length && videoGridRef.current && location.hash === "#videos") {
            videoGridRef.current.scrollIntoView({ behavior: "smooth" })
        }
    }, [ formattedPlayerVideos, videoGridRef.current ])

    if (isLoading) {
        return (<Preloader fixed/>)
    }

    return (
        <>
            <LeagueHeader title={"Détails du joueur"}>
                <TeamHeading team={currentTeam}/>
            </LeagueHeader>
            <div className={"league-player-details c-row justify-center league-layout"}>
                <div className="c-col c-col--12 no-padding content">
                    <motion.div
                        custom={0} variants={AnimationPresets.gridRevealBuilder} initial="hidden" className="block1"
                        animate="visible"
                    >
                        <PlayerCard player={playerProfile}/>
                    </motion.div>
                    <motion.div
                        custom={1} variants={AnimationPresets.gridRevealBuilder} initial="hidden" className="block2"
                        animate="visible"
                    >
                        {playerStatsSummary && <StatsSummary data={playerStatsSummary} layout={"player"}/>}
                    </motion.div>
                    {!!formattedPlayerVideos?.length && (
                        <motion.div
                            ref={videoGridRef}
                            custom={2} variants={AnimationPresets.gridRevealBuilder} initial="hidden" className="block3"
                            animate="visible"
                        >
                            <VideoGrid videos={formattedPlayerVideos}/>
                        </motion.div>
                    )}
                </div>
            </div>
        </>
    )
}
