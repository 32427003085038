import dayjs from "dayjs"
import PropTypes from "prop-types"

import { TeamGameSeries } from "../../parts/GameTeamDisplay/TeamGameSeries/TeamGameSeries.jsx"
import { VisualPlayerPicture } from "../../parts/VisualPlayerPicture/VisualPlayerPicture.jsx"
import { BaseLayout } from "../Base.jsx"

import "./Player.scss"

export const Player = (props) => {
    const { leagueTeam, player } = props.data

    const fivePastGamesResult = player?.fivePastGamesResult?.split(",")

    return (
        <BaseLayout decorator="left" cls="teamsRanking playerStats" withGradient>
            <div className={"heading"}>
                <div className="left">
                    <h3 className={"tw-regular uppercase"}><strong className={"tw-bold"}>
                        {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
                        SAISON {props.ctx.leagueSeason} //</strong> {dayjs().format("DD MMMM YYYY")}
                    </h3>
                    <span className={"tw-bold"}>LES CHAMPIONNATS INTER-ENTREPRISE</span>
                </div>
                <div className="right">
                    <h1 className={"tw-bold"}>Mes statistiques</h1>
                    <h2 className={"tw-bold uppercase"}>De saison</h2>
                </div>
            </div>
            <VisualPlayerPicture
                picture={player?.businessLeaguePictureUrl}
                width={800}
                zIndex={1}
                coordinates={{
                    left: 0,
                    top: 70,
                }}
            />
            <div className={"playerSummary"}>
                <div className={"info"}>
                    <img src={leagueTeam?.blasonUrl || leagueTeam?.blazon} className={"picture"}/>
                    <h1 className={"tw-bold uppercase"}>{leagueTeam?.name}</h1>
                    <h2 className={"tw-bold"}>{player?.firstname} <span className="uppercase">{player?.lastname}</span></h2>
                    <h3 className={"tw-regular"}>{player?.jobTitle}</h3>
                </div>
                <div className={"stats"}>
                    <div className={"statItem"}>
                        <h3 className={"tw-bold"}>{player?.nbGamePlayed}</h3>
                        <span className={"tw-regular"}>Apparitions</span>
                    </div>
                    <div className={"statItem"}>
                        <h3 className={"tw-bold"}>{player?.nbGoals}</h3>
                        <span className={"tw-regular"}>Buts</span>
                    </div>
                    <div className={"statItem"}>
                        <h3 className={"tw-bold"}>
                            {player.gamesPlayed ? (player.goals / player.gamesPlayed).toFixed(2) : 0.00}
                        </h3>
                        <span className={"tw-regular"}>Buts / Match</span>
                    </div>
                </div>
                <div className={"miscs"}>
                    <div className={"miscItem mvp"}>
                        <h3 className={"tw-bold"}>Homme du match</h3>
                        <p className={"tw-bold"}><span>{player?.nbMvp}</span></p>
                    </div>
                    {fivePastGamesResult[0] !== "" && (
                        <div className={"miscItem"}>
                            <h3 className={"tw-regular"}>Série en cours</h3>
                            <TeamGameSeries team={player}/>
                        </div>
                    )}
                </div>
            </div>
        </BaseLayout>
    )
}

Player.propTypes = {
    ctx: PropTypes.object,
    data: PropTypes.object,
}
