import dayjs from "dayjs"
import PropTypes from "prop-types"

import { GameTeamDisplay } from "../../parts/GameTeamDisplay/GameTeamDisplay.jsx"
import { TeamScorersSummary } from "../../parts/GameTeamDisplay/TeamScorersSummary/TeamScorersSummary.jsx"
import { Title } from "../../parts/Title/Title.jsx"
import { VisualPlayerPicture } from "../../parts/VisualPlayerPicture/VisualPlayerPicture.jsx"
import { BaseLayout } from "../Base.jsx"

import "./PassedGame.scss"

export const PassedGame = (props) => {
    const { gameDetails, league } = props.data
    const localTeamScore = gameDetails?.localTeam?.scoreTeam
    const visitorTeamScore = gameDetails?.visitorTeam?.scoreTeam

    return (
        <BaseLayout decorator="bottom" withGradient>
            <Title size={[ 66 ]} title={
                <span className="primary tw-regular"><strong className="tw-bold">{league?.name}</strong> Saison {props.ctx.leagueSeason}</span>
            } cls={"uppercase tw-lh-1"} />
            <Title size={[ 30 ]} title="LES CHAMPIONNATS INTER-ENTREPRISE" cls={"uppercase"} margin={[ 10, 0, 70, 0 ]} />
            <VisualPlayerPicture
                picture={
                    props.ctx?.selectedTeamPlayerPhoto
                    ?? gameDetails?.localTeam?.players?.find(player => player.isCaptain)?.businessLeaguePictureUrl
                    ?? null
                }
                width={650}
                zIndex={0}
                coordinates={{
                    bottom: 0,
                    left: -136,
                }}
            />
            <VisualPlayerPicture
                picture={
                    props.ctx?.selectedOpponentPlayerPhoto
                    ?? gameDetails?.visitorTeam?.players?.find(player => player.isCaptain)?.businessLeaguePictureUrl
                    ?? null
                }
                width={650}
                zIndex={0}
                mirror
                coordinates={{
                    bottom: 0,
                    right: -136,
                }}
            />
            <div className={"passedGame"}>
                <GameTeamDisplay team={gameDetails?.localTeam} bottomEl={<TeamScorersSummary teamId={gameDetails?.localTeam?.id} goals={gameDetails?.goals} />} size={180}  />
                <div className={"scoreContent"}>
                    <Title size={[ 44 ]} title="score final" cls={"uppercase tw-bold tw-lh-1"} />
                    <div className="score">
                        <span className={localTeamScore > visitorTeamScore ? "winning" : ""}>{localTeamScore}</span>
                        <div />
                        <span className={visitorTeamScore > localTeamScore ? "winning" : ""}>{visitorTeamScore}</span>
                    </div>
                    <Title size={[ 48, 30 ]} title={dayjs(gameDetails?.date).format("dddd DD MMMM | HH[h]mm")} subtitle={"URBANSOCCER " + league?.centerName} cls={"uppercase tw-bold"} />
                </div>
                <GameTeamDisplay team={gameDetails?.visitorTeam} bottomEl={<TeamScorersSummary teamId={gameDetails?.visitorTeam?.id} goals={gameDetails?.goals} />} size={180} />
            </div>
        </BaseLayout>
    )
}

PassedGame.propTypes = {
    ctx: PropTypes.object,
    data: PropTypes.object,
}
