import dayjs from "dayjs"
import PropTypes from "prop-types"
import { useMemo } from "react"

import { TeamPicture } from "../../../../../components/league/TeamPicture/TeamPicture.jsx"
import { TeamHeading } from "../../parts/TeamHeading/TeamHeading.jsx"
import { Title } from "../../parts/Title/Title.jsx"
import { VisualPlayerPicture } from "../../parts/VisualPlayerPicture/VisualPlayerPicture.jsx"
import { BaseLayout } from "../Base.jsx"

import "./TeamAgenda.scss"

export const TeamAgenda = (props) => {
    const { data, pagination, ctx } = props
    const { currentPage, limit } = pagination ?? {}

    const paginatedTeams = useMemo(() => {
        const startIndex = (currentPage - 1) * limit
        const endIndex = startIndex + limit
        return data?.teamGames.slice(startIndex, endIndex)
    }, [ data, currentPage, limit ])

    return (
        <BaseLayout withGradient>
            <TeamHeading data={props?.data} big ctx={ctx}/>
            <Title size={[ 40,30 ]} title={"PROCHAINES RENCONTRES"} subtitle={"UrbanSoccer " + ctx?.league?.centerName} cls={"uppercase" +
                " tw-bold"} margin={[ 6,0,0,0 ]}/>
            <VisualPlayerPicture
                picture={data?.leagueTeam?.pictureUrl}
                width={886}
                zIndex={1}
                coordinates={{
                    bottom: 150,
                    left: 34,
                    right: 0,
                }}
            />
            <div className={"teamAgenda"}>
                {paginatedTeams?.map((match, i) => (
                    <div key={"match-" + i} className={"match"}>
                        <TeamPicture image={
                            match?.visitorTeam?.blasonUrl
                            || match?.visitorTeam?.blazon
                            || "/assets/images/business/communicationPack/teamPlaceholder.png"
                        } size={140} />
                        <div className={"match-infos"}>
                            <div className={"matchdate"}>
                                {dayjs(match.date).format("dddd DD MMMM | HH[H]mm")}
                            </div>
                            <div className={"opponent"}>
                                {match.visitorTeam?.team}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </BaseLayout>
    )
}

TeamAgenda.propTypes = {
    ctx: PropTypes.object,
    data: PropTypes.object,
    pagination: PropTypes.object,
}
